import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppconfigService {

  constructor() { }

  Revision = "1"

//DESAROOLLO
// restApiServiceBaseUri= 'http://localhost:8080/CIDEJ_PSE_DMZ/webapi/' // REST Api
// dominio = 'http://cidejappdesa2.oj.gob.gt:8080/'//no es definitivo 
// restCONSULTACIT = 'http://serviciosrest.oj.gob.gt:8080/'
// restCONSULTARENAP = 'http://wsdesacit.oj.gob.gt:8080/GESTOR_INTERCONEXIONES/webapi/'//renap
// restCONSULTARCOLE = 'http://wsdesacit:8080/AGP_CIDEJ/webapi/'//cole 
// ApiGoogle= 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
// restOAUTH = 'http://wsdesacit.oj.gob.gt:8080/OAUTH2/webapi/' //oauth
// restRRHH = 'http://servicioscit.oj.gob.gt:8080/rrhh/' //rrhh prod
// public static siteKeyRecaptcha: string = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' //Prod
// sistemaId = 52  //id sistema  oauth  CITBASE2 16
// sistemaIdOAUTH = 2  //id sistema  oauth
// sistemaIdRRHH = 33
// validarCAPTCHA = 1

// restApiServiceBaseUri= 'http://cidejappdesa2.oj.gob.gt:8080/OJVIRTUAL_DMZ_DESA/webapi/' // REST Api
// dominio = 'http://cidejappdesa2.oj.gob.gt:8080/'//no es definitivo 
// restCONSULTACIT = 'http://serviciosrest.oj.gob.gt:8080/'
// restCONSULTARENAP = 'http://wsdesacit.oj.gob.gt:8080/GESTOR_INTERCONEXIONES/webapi/'//renap
// restCONSULTARCOLE = 'http://wsdesacit:8080/AGP_CIDEJ/webapi/'//cole 
// ApiGoogle= 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
// restOAUTH = 'http://wsdesacit.oj.gob.gt:8080/OAUTH2/webapi/' //oauth
// restRRHH = 'http://servicioscit.oj.gob.gt:8080/rrhh/' //rrhh prod
// public static siteKeyRecaptcha: string = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' //Prod
// sistemaId = 52  //id sistema  oauth  CITBASE2 16
// sistemaIdOAUTH = 2  //id sistema  oauth
// sistemaIdRRHH = 33
// validarCAPTCHA = 1


// //PRUEBAS
// restApiServiceBaseUri= 'http://cidejappdesa2.oj.gob.gt:8080/CIDEJ_PSE_DMZ_PR/webapi/' // REST Api
// dominio = 'http://cidejappdesa2.oj.gob.gt:8080/'//no es definitivo 
// restCONSULTACIT = 'http://serviciosrest.oj.gob.gt:8080/'
// restCONSULTARENAP = 'http://wsdesacit.oj.gob.gt:8080/GESTOR_INTERCONEXIONES/webapi/'//renap
// restCONSULTARCOLE = 'http://wsdesacit:8080/AGP_CIDEJ/webapi/'//cole 
// ApiGoogle= 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
// restOAUTH = 'http://wsdesacit.oj.gob.gt:8080/OAUTH2/webapi/' //oauth
// restRRHH = 'http://servicioscit.oj.gob.gt:8080/rrhh/' //rrhh prod
// public static siteKeyRecaptcha: string = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' //Prod
// sistemaId = 52  //id sistema  oauth  CITBASE2 16
// sistemaIdOAUTH = 2  //id sistema  oauth
// sistemaIdRRHH = 33
// validarCAPTCHA = 1

  //PRODUCCION
  restApiServiceBaseUri= 'https://servicioscidejdmz.oj.gob.gt:24335/OJVIRTUAL_DMZ/webapi/' // REST Api
  dominio = 'https://consultasexternas.oj.gob.gt/'//no es definitivo 
  restCONSULTACIT = 'https://serviciosrest.oj.gob.gt:9090/'
  restCONSULTARENAP = 'http://interconexionrenap.oj.gob.gt:8080/GESTOR_INTERCONEXIONES/webapi/'//renap
  restCONSULTARCOLE = 'http://jdcitbk02.oj.gob.gt:8080/AGP_CIDEJ/webapi/'//cole 
  ApiGoogle= 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
  restOAUTH = 'https://zaculeu.oj.gob.gt:24472/OAUTH_DMZ/webapi/' //oauth
public static siteKeyRecaptcha: string = '6LcqZK4ZAAAAAJ9bIqvRCXmMyfri9_tySq08hbj3' //Prod
sistemaId = 52  //id sistema  oauth  CITBASE2 16
sistemaIdOAUTH = 2  //id sistema  oauth
sistemaIdRRHH = 33
validarCAPTCHA = 1

  Sistema = 'OJVirtual'
}

