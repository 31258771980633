import { NgModule, LOCALE_ID, forwardRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatPaginatorIntl,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTreeModule } from '@angular/material/tree';

import { AppComponent } from './app.component';


import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { ContenedorLayoutComponent } from './layouts/contenedor-layout/contenedor-layout.component';
import { LandingPageLayoutComponent } from './layouts/landing_page/landing-page-layout.component';

import { AppRoutes } from './app.routing';
import { SidebarModule } from './Menu/left/sidebar.module';

import { FixedpluginModule } from './Menu/right/fixedplugin.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './recursos/authInterceptor';
import { LoginComponent } from './pages/login/login.component';
import { FixedpluginComponent } from './Menu/right/fixedplugin.component';
import { NavbarComponent } from './Menu/up/navbar.component';

import { HomeComponent } from './pages/home/home.component';


import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from './block-template.component';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { MatPaginatorIntlEspaniol } from './pagineo';

//import { NgxMaskModule, IConfig } from 'ngx-mask'
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaLoaderService } from 'ng-recaptcha';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter} from '@angular/material/core';

import { TextMaskModule } from 'angular2-text-mask';

import { MatFormFieldModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { TreeComponent } from './componentes/tree/tree.component';

import { ModalVisorNotificacionComponent } from './consultasExternas/notificacionesEstrados/modales/visorNotificacion/modal.visorNotificacion.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
}; 


@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    PdfJsViewerModule
  ],  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ],
  declarations: []
})
export class MaterialModule {}

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        HttpModule,
        MaterialModule,
        MatNativeDateModule,
        SidebarModule,
        HttpClientModule,
        ReactiveFormsModule,
        //NgxMaskModule.forRoot(),
        BlockUIModule.forRoot({
          template: BlockTemplateComponent
        }), // Import BlockUIModule
        BlockUIHttpModule.forRoot(), // Import Block UI Http Module
        RecaptchaModule,
        TextMaskModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        MatTreeModule
        //PdfJsViewerModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        ContenedorLayoutComponent,
        LandingPageLayoutComponent,
        LoginComponent,
        HomeComponent,
        FixedpluginComponent,
        NavbarComponent,
        BlockTemplateComponent,
        TreeComponent,
        ModalVisorNotificacionComponent
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: forwardRef(() => MatPaginatorIntlEspaniol)
    },
    { provide: LOCALE_ID, useValue: 'es-GT' }, // configuracion español
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        //DESARROLLO
        //siteKey: '6LchIfgZAAAAAJEH-6Jef5flj0NNKx4W6gujy8hk'
        //PRUEBAS
        siteKey: '6LchIfgZAAAAAJEH-6Jef5flj0NNKx4W6gujy8hk'
        
      } as RecaptchaSettings
    }
  ],
  entryComponents: [
    BlockTemplateComponent, // Make sure to add it to the entry components
    ModalVisorNotificacionComponent,
  ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }
