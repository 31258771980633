import { Component, OnInit, Input } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router } from '@angular/router';
import { SharedService } from '../../recursos/SharedDataService';
import { AuthService } from '../../recursos/auth.service';
import { AppconfigService } from '../../appconfig.service';

declare const $: any;

//Metadata
export class RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    parametro: string;
    children?: ChildrenItems[] = new Array<ChildrenItems>();
    constructor() { }
}

export class ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    parametro: string;
    constructor() { }
}

//Menu Items
export const ROUTES: RouteInfo[] = new Array<RouteInfo>();
const menu = [
    {
        "ICONO": "fa fa-calendar",
        "MENU": "Agenda de Audiciencias",
        "URL_MENU": "consultasExternas/AgendaAudiencias",
        "hijos": []
    },
    {
        "ICONO": "fa fa-users",
        "MENU": "Consulta Recede",
        "URL_MENU": "consultasExternas/Recede",
        "hijos": []
    },
    {
        "ICONO": "fa fa-university",
        "MENU": "Consulta Amparos",
        "URL_MENU": "consultasExternas/Amparos",
        "hijos": []
    },
    {
        "ICONO": "fa fa-file",
        "MENU": "Consulta Antejuicios",
        "URL_MENU": "consultasExternas/Antejuicios",
        "hijos": []
    },
    {
        "ICONO": "fa fa-file-pdf-o",
        "MENU": "Verificador de Documentos",
        "URL_MENU": "consultasExternas/VerificarDocumentos",
        "hijos": []
    },
    {
        "ICONO": "fa fa-file",
        "MENU": "Consulta Pensión Alimenticia",
        "URL_MENU": "consultasExternas/PensionAlimenticia",
        "hijos": []
    },
    {
        "ICONO": "fa fa-file",
        "MENU": "Consulta Prestación Laboral",
        "URL_MENU": "consultasExternas/PrestacionLaboral",
        "hijos": []
    },
    {
        "ICONO": "fa fa-list-alt",
        "MENU": "Notificaciones por Estrado",
        "URL_MENU": "consultasExternas/NotisElectronicasEstrados",
        "hijos": []
    }
]


@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html'
})

export class SidebarComponent implements OnInit {
    public menuItems: any[] = new Array<any>();

    public menuUsuario: any[] = new Array<any>();
    public a: boolean = false;

    revision = "";

    constructor(
        private router: Router,
        private sharedService: SharedService,
        private au: AuthService,
        private appSettings: AppconfigService,
    ) { }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        //this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.revision = this.appSettings.Revision;
        this.cargarMenu()
    }

    cargarMenu() {

        let RutaParam = [];
        this.menuUsuario = menu
        this.menuItems = new Array<any>();
        this.menuUsuario.forEach(menu => {
            let t: RouteInfo = new RouteInfo();
            t.path = menu.URL_MENU
            t.title = menu.MENU
            t.icontype = menu.ICONO
            t.parametro = menu.PARAMETROS

            RutaParam.push({'URL_MENU': menu.URL_MENU, 'PARAMETROS': menu.PARAMETROS });

            if (menu.hijos.length > 0) {
                t.type = 'sub'
                t.collapse = menu.URL_MENU
                menu.hijos.forEach(hijo => {
                    let h: ChildrenItems = new ChildrenItems();
                    //{path: 'hijo', title: 'Hijo', ab:'HJ'},
                    h.path = hijo.URL_MENU
                    h.title = hijo.ABREVIACION
                    h.ab = hijo.ICONO
                    h.parametro = hijo.PARAMETROS
                    t.children.push(h)

                    RutaParam.push({'URL_MENU': menu.URL_MENU + '/' +hijo.URL_MENU, 'PARAMETROS': hijo.PARAMETROS });
                });
            } else {
                t.type = 'link'
            }

            this.menuItems.push(t)
            ROUTES.push(t);
        });

       // this.au.saveLocalData('RutaParam', JSON.stringify(RutaParam));
        console.log('RutaParam', RutaParam);
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }


    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }


    ControlParametros(menuitem) {
        //console.log('PARAMETROS',menuitem.parametro);
        
        try{
            this.sharedService.nextMessage(JSON.parse(menuitem.parametro))
            //console.log('PARAMETROS PARSE',JSON.parse(menuitem.parametro));
        }catch(e){
            //console.log(menuitem.parametro);
            console.log(e);
        }
        //this.sharedService.nextMessage(menuitem.parametro)
    }
}
