import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppconfigService } from '../../appconfig.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesEstradoService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token'
    })
  };

  constructor(private http: HttpClient, private appSettings: AppconfigService) { }


  getDepartamentos(): Observable<any> {
    return this.http.get<any>(this.appSettings.restApiServiceBaseUri + 'Catalogos/GetDepartamentos')
      .pipe(
        catchError(this.handleError('getDepartamentos', []))
      );
  }

  getMunicipios(idDepartamento): Observable<any> {
    return this.http.get<any>(this.appSettings.restApiServiceBaseUri + 'Catalogos/Departamento/' + idDepartamento + '/GetMunicipios')
      .pipe(
        catchError(this.handleError('getMunicipios', []))
      );
  }

  getDespachosByMunicipio(idMunicipio): Observable<any> {
    return this.http.get<any>(this.appSettings.restApiServiceBaseUri + 'AgendaAudiencia/Municipio/'+ idMunicipio + '/GetDespachos')
    .pipe(
      catchError(this.handleError('getDespachosByMunicipio', []))
    );
  }

  ObtenerNotificacionesEstrados(dato): Observable<any>{
    //this.httpOptions.headers = this.httpOptions.headers.set('Authorization', gtoken);
    return this.http.post<any>(this.appSettings.restApiServiceBaseUri+'ConsultasExternas/ObtenerNotificacionesEstrados',dato)
    .pipe(
      catchError(this.handleError('ObtenerNotificacionesEstrados', []))
    );
  }

  ObtenerCantidadNotificacionesEstrados(dato): Observable<any>{
    return this.http.post<any>(this.appSettings.restApiServiceBaseUri+'ConsultasExternas/ObtenerCantidadNotificacionesEstrados',dato)
    .pipe(
      catchError(this.handleError('ObtenerCantidadNotificacionesEstrados', []))
    );
  }

  ObtenerNotificacionElectronica(id_notificacion, gtoken): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', 'g-response ' + gtoken);
    return this.http.get<any>(this.appSettings.restApiServiceBaseUri + 'ConsultasExternas/' + id_notificacion + '/ObtenerNotificacionElectronica' , {headers: headers})
      .pipe(
        catchError(this.handleError('ObtenerNotificacionElectronica', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log('xx error xx', error.message)

      return of(result as T);
    };
  }
  private getURL(urlServer, Servicio) {
    return urlServer + Servicio;
  }
  
}
