import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

  private parametro = new BehaviorSubject({});
  sharedMessage = this.parametro.asObservable();

  constructor() { }

  nextMessage(data: any) {
    this.parametro.next(data);
  }

  cleanParams(){
    var dataVacia = {};
    this.parametro.next(dataVacia);
  }
  
}