import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { AuthService } from '../../recursos/auth.service';
import { AppconfigService } from '../../appconfig.service';


const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare const $: any;

@Component({
    selector: 'app-landing-page-layout-component',
    templateUrl: './landing-page-layout.html',
    styles: [`.active { background-color: crimson; }`]
})

export class LandingPageLayoutComponent implements OnInit, AfterViewInit {


    constructor(private router: Router,
        private location: Location,
        private route: ActivatedRoute,
        private authService: AuthService,
        private appSettings: AppconfigService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        /*
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function() {
            body.classList.add('sidebar-mini');
            misc.sidebar_mini_active = true;
        }, 300);
        */
    }

    minimizeSidebar(){
        const body = document.getElementsByTagName('body')[0];
  
        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
  
        } else {
            setTimeout(function() {
                body.classList.add('sidebar-mini');
  
                misc.sidebar_mini_active = true;
            }, 300);
        }
  
        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function() {
            window.dispatchEvent(new Event('resize'));
        }, 180);
  
        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function() {
            clearInterval(simulateWindowResize);
        }, 1000);
      }

}



