import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { AppconfigService } from '../appconfig.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../recursos/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token'
    })
  };

  constructor(private http: HttpClient, private appSettings: AppconfigService, private au: AuthService) { }


  descargarArchivo(id_archivo, gtoken): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', 'g-response ' + gtoken);
    return this.http.get(this.appSettings.restApiServiceBaseUri + 'ConsultasExternas/Archivo/' + id_archivo + '/DesacargarArchivo', {headers: headers, 
      observe: 'response',
      responseType: 'blob'
    });
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log('xx error xx', error.message)

      return of(result as T);
    };
  }

  private getURL(urlServer, Servicio) {
    return urlServer + Servicio;
  }
}
