
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppconfigService } from '../appconfig.service';
import { cryptoOJ } from './cryptoOJ';

import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { recursosVarios } from './recursosVarios';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private localStorageService;

  constructor(private appSettings: AppconfigService, private router: Router, private authService: AuthService) {
    this.localStorageService = localStorage;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req.clone();


    if (!authReq.headers.get('Authorization')) {
      var dateObj = new Date();
      var fecha256 = new cryptoOJ().sha256("CIT-" + this.appSettings.Sistema + "-D" + dateObj.getDate() + "-M" + dateObj.getMonth() + "-Y" + dateObj.getFullYear());
      authReq = req.clone({ setHeaders: { Authorization: `Bearer ${fecha256}` } });
    }


    if (req.url.startsWith(this.appSettings.restOAUTH)) {
      var tokenOAUTH = this.localStorageService.getItem('token' + this.appSettings.sistemaIdOAUTH);
      if (tokenOAUTH) {
        authReq = req.clone({ setHeaders: { Authorization: `Bearer ${tokenOAUTH}` } });
      }
    }

    if (req.url.startsWith(this.appSettings.restCONSULTARENAP)) {
      authReq = req.clone({ setHeaders: { Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJJRF9TSVNURU1BIjoiMiIsIklOVEVSQ09ORVhJT04iOiIxIiwiQU1CSUVOVEUiOiIyIiwiU0lTVEVNQSI6IkNBUEUiLCJBTklPIjoiMjAxOCJ9.ofyYHap4ZuYlfzA24ZQ8faDwIBhIrVEamW4OZbE96q8` } });
    }

    if (req.url.startsWith(this.appSettings.restCONSULTARCOLE)) {
    }

    if (req.url.startsWith(this.appSettings.restCONSULTACIT + 'LABORAL_DMZ')) {
      authReq = req.clone({ setHeaders: { Authorization: `Bearer laboral` } });
    }

    return next.handle(authReq).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        //todo bien
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          console.log('401  ->', err)
          new recursosVarios().showNotification('top', 'right', 'Ocurrrio un error con un servicio.', 4)
          // new recursosVarios().showNotification('top','right',err.error,4)
          this.router.navigate(['/']);
        }
      }
    });
  }

  recaptchaHeader(url) {

    if (
      url.endsWith('/ObtenerNotificacionElectronica') ||
      url.endsWith('/DesacargarArchivo') ||
      url.endsWith('/GetAudienciasXExpedienteSgt1') ||
      url.endsWith('/GetAudienciasXFechaEstadoSgt1')
    ) {

      return true
    }

    return false
  }

}