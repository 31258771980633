declare const $: any;
import { ArchivosService } from '../recursos/archivos.service';
import { Optional } from '@angular/core';
import * as moment from 'moment';

export class recursosVarios {

    constructor(
    ) { }

    public showNotification(from: any, align: any, msg: any, color: number) {
        const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
        /* color:number valores,
            0 Blanco
            1 celeste
            2 verde
            3 anaranjado
            4 rojo
            5 rosado
            6 azul
        */
        //const color = Math.floor((Math.random() * 6) + 1);
        let icon = 'notifications'
        switch (color) {
            case 2: {
                icon = 'check';
                break;
            }
            case 3: {
                icon = 'warning';
                break;
            }
            case 4: {
                icon = 'error_outline';
                break;
            }
        }

        $.notify({
            icon: icon,
            message: msg
        }, {
            type: type[color],
            timer: 3000,
            placement: {
                from: from,
                align: align
            },
            template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon NOTIFICACION" role="alert">' +
                '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
                '<i class="material-icons" data-notify="icon">' + icon + '</i> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                '</div>' +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                '</div>'
        });
    }

    public descargarArchivo(archivosService: any, id_archivo: any) {

        archivosService.descargarArchivo(id_archivo).subscribe(data => {
            console.log(data.headers);
            console.log(data.headers.get('Content-Disposition'));
            let blob: any = new Blob([data.body], { type: data.headers.get('Tipo-Archivo') });

            const url = window.URL.createObjectURL(blob);
            window.open(url);
        }), error => console.log('Error en la descarga del archivo'),
            () => console.info('Archivo descargado');


    }

    public formattedDate(date: String) {
        //  Convert a "dd/MM/yyyy" string into a Date object
        console.log(date);
        if (date != "") {
            let d = date.split("/");
            let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
            return dat;
        } else {
            return "";
        }

    }

    //FUNCION PARA DARLE FORMATO A LAS FECHAS DD/MM/YYYY
    public formatDate_Fget(fecha: any) {
        let fechaTemp = moment(fecha);
        return fechaTemp.format('DD-MM-YYYY');
    }

    //FUNCION PARA DARLE FORMATO A LAS FECHAS DD/MM/YYYY
    public formatDateG(fecha: any) {
        let fechaTemp = moment(fecha);
        return fechaTemp.format('DD/MM/YYYY');
    }

    //FUNCION PARA DARLE FORMATO A LAS FECHAS DD/MM/YYYY
    public formatDate(fecha: any) {
        let fechaTemp = moment(fecha);
        return fechaTemp.format('DD/MM/YYYY');
    }

    //FUNCION PARA OBTENER LA DIFERENCIA DE DIAS ENTRE 2 FECHAS
    public diferenciaDiasDates(fecha_ini: any, fecha_fin: any) {
        let FI = moment(fecha_ini);
        let FF = moment(fecha_fin);
        let DIF = Math.abs(FI.diff(FF, 'days'));
        return DIF;
    }

    //FUNCION PARA SABER SI UNA FECHA ES MAYOR QUE OTRA
    public isDateSameOrAfter(fecha_ini: any, fecha_fin: any) {
        let FI = moment(fecha_ini);
        let FF = moment(fecha_fin);
        console.log(FI.diff(FF), FI.diff(FF) >= 0);
        return FI.diff(FF) >= 0;
    }

    //FUNCION PARA SABER SI UNA FECHA ES MAYOR QUE OTRA
    public isDateSameOrBefore(fecha_ini: any, fecha_fin: any) {
        let FI = moment(fecha_ini);
        let FF = moment(fecha_fin);
        console.log(FI.diff(FF), FI.diff(FF) < 0);
        return FF.diff(FI) < 0;
    }

    //FUNCION PARA SABER SI UNA FECHA SE ENCUENTRA ENTRE 2 FECHAS
    public dateEntreFechas(fecha: any, fecha_ini: any, fecha_fin: any) {
        let F = moment(fecha);
        let FI = moment(fecha_ini).add(-1, 'days');
        let FF = moment(fecha_fin).add(1, 'days');;

        console.log(F.isBetween(FI, FF));
        return F.isBetween(FI, FF);
    }



}