import { Component, OnInit, AfterViewInit, Inject, ViewChild , ViewEncapsulation  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { NotificacionesEstradoService } from '../../notificacionesEstrados.service';
import { ArchivosService } from '../../../../recursos/archivos.service';

import { MatDialog, MatDialogConfig } from "@angular/material";

@Component({
    selector: 'app-my-modal-visor-notificacion',
    templateUrl: './modal.visorNotificacion.component.html',
    styleUrls: ["./modal.visorNotificacion.component.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class ModalVisorNotificacionComponent implements OnInit, AfterViewInit {


    @ViewChild('pdfViewerAutoLoadReso') pdfViewerAutoLoadReso: { pdfSrc: any; refresh: () => void; };
    @ViewChild('pdfViewerAutoLoadActa') pdfViewerAutoLoadActa: { pdfSrc: any; refresh: () => void; };
    @ViewChild('pdfViewerAutoLoadAnexo') pdfViewerAutoLoadAnexo: { pdfSrc: any; refresh: () => void; };

    @ViewChild('tabs') tabs;

    @ViewChild('paginatorActa') paginatorActa;
    @ViewChild('paginatorResolucion') paginatorResolucion;
    @ViewChild('paginatorAnexos') paginatorAnexos;

    Textos: any = [];
    formAI: FormGroup;
    infoRepresentante: any = [];
    catalogoGenero: any = [];
    dataModal: any = [];
    Orden: any = [];
    Notificacion: any = [];

    ArchivosActa: any = [];
    ArchivosResolucion: any = [];
    ArchivosAnexos: any = [];

    Descripcion:any = [];

    //Tabla Archivos
    TablaArchivos: any = [];
    dataSourceTablaArchivos = new MatTableDataSource<any>();
    displayedColumnsArchivos: string[] = ['NOMBRE_ARCHIVO', 'TIPO_DOCUMENTO', 'OBSERVACIONES', 'ACCIONES'];

    //Tabla Envios
    TablaEnvios: any = [];
    dataSourceTablaEnvios = new MatTableDataSource<any>();
    displayedColumnsEnvios: string[] = ['SEDE', 'FECHA_REGISTRO', 'FECHA_LEIDO', 'ESTADO'];

    constructor(
        //private au: AuthService,
        public dialogRef: MatDialogRef<ModalVisorNotificacionComponent>,
        private formBuilder: FormBuilder,
        private changeDetectorRefs: ChangeDetectorRef,
        private notificacionesService: NotificacionesEstradoService,
        private archivosService: ArchivosService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild(MatPaginator) paginator2: MatPaginator;
    @ViewChild(MatSort) sort2: MatSort;

    cerrarModal(): void {
        this.data.cambio = "S";
        this.dialogRef.close(this.data);
    }

    ngOnInit() {

        console.log('MODAL NOTI',this.data);

        this.notificacionesService.ObtenerNotificacionElectronica(this.data.ID_NOTIFICACION, this.data.grecaptcha ).subscribe(data => {
            //his.catalogoDepartamentos = data;
            console.log(data);
            this.Notificacion = data;
            this.obtenerArchivosNotificacion(data);
        });

    }

    refresh() {
        this.changeDetectorRefs.detectChanges();
    }

    obtenerArchivosNotificacion(Noti) {

        //console.log('tabs',this.tabs);
        this.tabs.selectedIndex = 0;

        this.paginatorActa.firstPage();
        this.paginatorResolucion.firstPage();
        this.paginatorAnexos.firstPage();

        let tempArchivosActa = [];
        let tempArchivosResolucion = [];
        let tempArchivosAnexos = [];

        //Obtenemos el Archivo para el Acta
        tempArchivosActa = [{
            'DESCRIPCION': 'Acta',
            'ID_ARCHIVO': Noti.ID_ARCHIVO_ACTA
        }]

        //this.ObtenerArchivoActa( tempArchivosActa[0]);


        //Obtenemos los Archivos de las Resoluciones / Anexos
        Noti.RESOLUCIONES.forEach(function (resolucion) {

            //Obtenemos los Archivos de la Resolucion
            let temp = { 'DESCRIPCION': resolucion.DESCRIPCION, 'ID_ARCHIVO': resolucion.ID_ARCHIVO };
            tempArchivosResolucion.push(temp);

            //this.ObtenerArchivoResolucion( tempArchivosResolucion[0]);

            //Obtenemos los Archivos de los Anexos
            resolucion.ANEXOS.forEach(function (anexo) {

                //Obtenemos el Anexo
                let temp = { 'DESCRIPCION': anexo.DESCRIPCION, 'ID_ARCHIVO': anexo.ID_ARCHIVO };
                tempArchivosAnexos.push(temp);

                //this.ObtenerArchivoAnexo( tempArchivosAnexos[0]);

            });

        });

        this.ObtenerArchivoActa( tempArchivosActa[0].ID_ARCHIVO);
        this.ObtenerArchivoResolucion( tempArchivosResolucion[0].ID_ARCHIVO);


        if( this.ArchivosAnexos.length > 0){
            this.ObtenerArchivoAnexo( tempArchivosAnexos[0].ID_ARCHIVO);
            this.Descripcion.Anexo =  tempArchivosAnexos[0].DESCRIPCION;
        }
        
        this.Descripcion.Acta =  tempArchivosActa[0].DESCRIPCION;
        this.Descripcion.Resolucion =  tempArchivosResolucion[0].DESCRIPCION;

        this.ArchivosActa = tempArchivosActa;
        this.ArchivosResolucion = tempArchivosResolucion;
        this.ArchivosAnexos = tempArchivosAnexos;

        this.Textos.TITULO_ACTA = "Acta (" + this.ArchivosActa.length + ")";
        this.Textos.TITULO_RESOLUCION = "Resolucion (" + this.ArchivosResolucion.length + ")";
        this.Textos.TITULO_ANEXO = "Anexos (" + this.ArchivosAnexos.length + ")";

    }

    changeActa(event) {
        //make something
        console.log(event.pageIndex);
        console.log(this.ArchivosActa[event.pageIndex]);
        this.Descripcion.Acta =  this.ArchivosActa[event.pageIndex].DESCRIPCION;

        this.ObtenerArchivoActa(this.ArchivosActa[event.pageIndex].ID_ARCHIVO)
    }

    changeResolucion(event) {
        //make something
        console.log(event.pageIndex);
        console.log(this.ArchivosResolucion[event.pageIndex]);
        this.Descripcion.Resolucion =  this.ArchivosResolucion[event.pageIndex].DESCRIPCION;
        this.ObtenerArchivoResolucion(this.ArchivosResolucion[event.pageIndex].ID_ARCHIVO)
    }

    changeAnexo(event) {
        //make something
        console.log(event.pageIndex);
        console.log(this.ArchivosAnexos[event.pageIndex]);
        this.Descripcion.Anexo =  this.ArchivosAnexos[event.pageIndex].DESCRIPCION;
        this.ObtenerArchivoAnexo(this.ArchivosAnexos[event.pageIndex].ID_ARCHIVO)
    }

    ObtenerArchivoActa(id_archivo){

        this.archivosService.descargarArchivo(id_archivo, this.data.grecaptcha).subscribe(data => {
          
            console.log(data.headers);
            console.log(data.headers.get('Content-Disposition'));
            let blob: any = new Blob([data.body], { type: data.headers.get('Tipo-Archivo') });

            const url = window.URL.createObjectURL(blob);
            //window.open(url);

            this.pdfViewerAutoLoadActa.pdfSrc = blob; // pdfSrc can be Blob or Uint8Array
            this.pdfViewerAutoLoadActa.refresh(); // Ask pdf viewer to load/refresh pdf
        })
    }

    ObtenerArchivoResolucion(id_archivo){

        this.archivosService.descargarArchivo(id_archivo, this.data.grecaptcha).subscribe(data => {
          
            console.log(data.headers);
            console.log(data.headers.get('Content-Disposition'));
            let blob: any = new Blob([data.body], { type: data.headers.get('Tipo-Archivo') });

            const url = window.URL.createObjectURL(blob);
            //window.open(url);

            this.pdfViewerAutoLoadReso.pdfSrc = blob; // pdfSrc can be Blob or Uint8Array
            this.pdfViewerAutoLoadReso.refresh(); // Ask pdf viewer to load/refresh pdf
        })
    }

    ObtenerArchivoAnexo(id_archivo){

        this.archivosService.descargarArchivo(id_archivo, this.data.grecaptcha).subscribe(data => {
          
            console.log(data.headers);
            console.log(data.headers.get('Content-Disposition'));
            let blob: any = new Blob([data.body], { type: data.headers.get('Tipo-Archivo') });

            const url = window.URL.createObjectURL(blob);
            //window.open(url);

            this.pdfViewerAutoLoadAnexo.pdfSrc = blob; // pdfSrc can be Blob or Uint8Array
            this.pdfViewerAutoLoadAnexo.refresh(); // Ask pdf viewer to load/refresh pdf
        })
    }

    verModoRevista(){

        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.data = this.Notificacion;
        dialogConfig.maxWidth = '100vw';
        dialogConfig.maxHeight = '100vw';
        dialogConfig.width = '90vw';
        dialogConfig.height = '95%';
        dialogConfig.panelClass = 'custom-modalbox';
    
        /*
        let dialogRef = this.dialog.open(ModalVisorNotificacionRevistaComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {

        });
        */

    }

    ngAfterViewInit() {

        this.dataSourceTablaArchivos.paginator = this.paginator;
        this.dataSourceTablaArchivos.sort = this.sort;

        this.dataSourceTablaEnvios.paginator = this.paginator2;
        this.dataSourceTablaEnvios.sort = this.sort2;
    }

    /* Handle form errors in Angular 8 */
    public errorHandling = (control: string, error: string) => {
        return this.formAI.controls[control].hasError(error);
    }

}