import { NgModule } from '@angular/core';

import { LoadChildrenCallback, RouterModule, Routes } from '@angular/router';

import { ContenedorLayoutComponent } from './layouts/contenedor-layout/contenedor-layout.component';
import { HomeComponent } from './pages/home/home.component';

export const AppRoutes: Routes = [
    {
        path: 'consultasExternas',
        component: ContenedorLayoutComponent,
        children: [
            {
                path: '',
                loadChildren:'./consultasExternas/consultasExternas.module#ConsultasExternas'
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'consultasExternas/Menu'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(AppRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
