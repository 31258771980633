import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import PerfectScrollbar from 'perfect-scrollbar';
import { NavbarComponent } from '../../Menu/up/navbar.component';
import { AuthService } from '../../recursos/auth.service';
import { AppconfigService } from '../../appconfig.service';
import { recursosVarios } from '../../recursos/recursosVarios';
import { SidebarComponent } from '../../Menu/left/sidebar.component';
import { cryptoOJ } from '../../recursos/cryptoOJ';
import { FixedpluginComponent } from '../../Menu/right/fixedplugin.component';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare const $: any;

@Component({
    selector: 'app-layout-component',
    templateUrl: './contenedor-layout.component.html',
    styleUrls: ['./contenedor-layout.component.scss']
})

export class ContenedorLayoutComponent implements OnInit, AfterViewInit {

    xx:any
    constructor(private router: Router,
        private location: Location,
        private route: ActivatedRoute,
        private authService: AuthService,
        private appSettings: AppconfigService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        /*
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function() {
            body.classList.add('sidebar-mini');
            misc.sidebar_mini_active = true;
        }, 300);
        */
       this.minimizeSidebar()
       this.xx =true
    }

    minimizeSidebar(){
        const body = document.getElementsByTagName('body')[0];
  
        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
  
        } else {
            setTimeout(function() {
                body.classList.add('sidebar-mini');
  
                misc.sidebar_mini_active = true;
            }, 300);
        }
  
        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function() {
            window.dispatchEvent(new Event('resize'));
        }, 180);
  
        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function() {
            clearInterval(simulateWindowResize);
        }, 1000);
      }

      public isMap() {
        if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
            return true;
        } else {
            return false;
        }
    }

}



